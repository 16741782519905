import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import PurposeDetailModal from './PurposeDetailModal';
import { IconButton } from '@mui/material';
import { authService } from '../../../..';
import { SatelliteAlt, Search } from '@mui/icons-material';
import { PiFileCsv } from 'react-icons/pi';
import DuplicateButtonWithSpinner from './DuplicateButtonWithSpinner';
import { useLocation } from 'react-router-dom';
import ListPageWrapper from '../../../Tools/ListPageWrapper';

type PurposesListProps = {
    filters: string;
};

export const PurposesList = ({ filters }: PurposesListProps) => {
    const { t } = useTranslation();
    const [sentItem, setSentItem] = useState<any>(null);
    const today = new Date();
    const filename = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}-${today.getHours()}h${today.getMinutes()}m${today.getSeconds()}s`;
    const exportFileName = `listes-intentions-${filename}.csv`;
    const { notif, notifOptions } = useNotif();
    const { search } = useLocation();

    const campaign__in = search.split('&').find((el) => el.includes('campaign='));

    if (
        campaign__in &&
        !filters.includes(campaign__in) &&
        filters.replace('&', '') !== campaign__in
    )
        filters = filters + '&' + campaign__in;

    return (
        <>
            <ListPageWrapper
                endpoint={'/api/purposes/'}
                fields={[
                    {
                        name: 'created_at',
                        label: 'Date',
                        className: 'text-center',
                        options: { width: '180px' },
                        transform: (value: any) => {
                            let date = value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss');
                            return date || '';
                        },
                        orderable: true
                    },
                    {
                        name: 'id',
                        label: 'Id',
                        className: 'text-center',
                        options: { width: '50px' }
                    },
                    {
                        name: 'aggregated_state',
                        label: 'Statut',
                        className: 'text-center',
                        transform: (status: any) => {
                            return t('status.' + status);
                        },
                        orderable: false
                    },
                    {
                        name: 'device',
                        label: 'Equipement',
                        className: 'text-center',
                        transform: (value: any) => {
                            return value?.module_address;
                        },
                        orderable: false
                    }
                ]}
                actions={[
                    {
                        name: 'detail',
                        label: 'Voir',
                        render: (sentItem: any) => (
                            <IconButton
                                onClick={() => {
                                    axios
                                        .get('/api/purposes/' + sentItem.id + '/')
                                        .then((results) => setSentItem(results.data));
                                    setSentItem(sentItem);
                                }}>
                                <Search fontSize="small" />
                            </IconButton>
                        )
                    },
                    {
                        name: 'duplicate',
                        label: 'Dupliquer cette intention',
                        render: (sentItem: { campaign: number; id: number | string }) => {
                            return (
                                <DuplicateButtonWithSpinner
                                    url={'/api/campaigns/' + sentItem.campaign + '/'}
                                    purpose={sentItem}
                                />
                            );
                        }
                    },
                    {
                        name: 'refresh',
                        label: 'Rafraîchir',
                        render: (sentItem: any) => {
                            return authService.canAccess('REFRESH_PURPOSES') &&
                                ['processing', 'pending', 'processed'].includes(sentItem.state) ? (
                                <IconButton
                                    onClick={() => {
                                        axios
                                            .post('/api/refresh-status/', {
                                                purpose_id: sentItem.id
                                            })
                                            .then(
                                                () => {
                                                    notif({
                                                        type: 'success',
                                                        content: "L'intention va être mise à jour"
                                                    });
                                                },
                                                () => {
                                                    notif({
                                                        type: 'error',
                                                        title: 'Erreur',
                                                        content:
                                                            "Une erreur est survenue lors de la mise à jour de l'intention"
                                                    });
                                                }
                                            );
                                    }}>
                                    <SatelliteAlt fontSize="small" />
                                </IconButton>
                            ) : (
                                <></>
                            );
                        }
                    }
                ]}
                filters={filters}
                exportButtons={[
                    {
                        name: 'Exporter en csv',
                        fileName: exportFileName,
                        type: 'csv',
                        icon: <PiFileCsv />,
                        requestEndpoint: 'api/purposes/export/',
                        requestMethod: 'POST',
                        responseType: 'json',
                        onSuccess: () => {
                            notif({
                                type: 'success',
                                title: 'Succès',
                                content:
                                    "L'export est en cours, vous le recevrez par mail lorsqu'il sera terminé"
                            });
                        }
                    }
                ]}
                // defaultOrder={['-created_at']}
                websocketCallback={(results: any, websocketData: any) => {
                    let newResults: any = [...results];
                    if (websocketData.type === 'refresh_purposes_status') {
                        newResults.forEach((result: any, idx: number) => {
                            if (
                                websocketData.state &&
                                websocketData.purpose_id &&
                                result.id === websocketData.purpose_id
                            ) {
                                newResults[idx].state = websocketData.state;
                            }
                        });
                    }

                    return newResults;
                }}
            />

            {sentItem && (
                <PurposeDetailModal
                    sentItem={sentItem}
                    onClose={() => {
                        setSentItem(null);
                    }}
                />
            )}
            <BirdzNotif options={notifOptions} />
        </>
    );
};

export default PurposesList;
