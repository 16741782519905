import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      Accueil: "Home",
      "Bienvenue sur le projet B-Link": "Welcome on the B-Link project",
      status: {
        scheduled: "Scheduled",
        pending: "Pending",
        processing: "Processing",
        processed: "Pending",
        error: "Error",
        ack_timeout: "ACK timeout",
        command_succeed: "Command succeed",
        command_failed: "Command failed",
        sent: "Sent",
        archived: "Archived",
        sent_without_ack: "sent_without_ack",
      },
    },
  },
  fr: {
    translation: {
      activate: "Activé",
      desactivate: "Désactivé",
      desactivated: "Désactivé",
      status: {
        sent_without_ack: "envoi sans ACK",
        queued: "En file d'attente",
        scheduled: "Planifié",
        pending: "En cours",
        processing: "En traitement",
        processed: "En attente",
        error: "Erreur",
        ack_timeout: "ACK délai dépassé",
        command_succeed: "Commande réussie",
        command_failed: "Commande échouée",
        command_applied: "Commande prise en compte",
        sent: "Envoyé",
        created: "Créé",
        stopped: "Stoppée",
        creating_purposes: "Création des intentions",
        sending_purposes: "Envoi des intentions",
        polling_purposes: "Polling des intentions",
        done: "Terminé",
        retrying: "En cours d'envoi à nouveau",
        expired: "Expirée",
        canceled: "Annulée",
        cancelled: "Annulée",
        deleted: "Supprimée",
        started: "Débuté",
        finished: "Terminé",
        updated: "Mis à jour",
        archived: "Archivée",
      },
      campaign__operator__name: "Opérateur",
      campaign__template__name: "Modèle",
      state: "Statut",
      scheduled_date: "Date d'envoi",
      device: "Equipement",
      payload_clear: "Payload",
      campaign__header_config: "Header concentrateur",
      frame: "Trame",
      encrypted_frame: "Trame chiffrée",
      encapsulated_frame: "Trame encapsulée",
      operator_command_id: "ID commande opérateur",
      history: "Historique",
      created_at: "Créé le",
      force_update: "Forcer la mise à jour",
      affiliate_module_address: "Adresse du père",
      affiliate_module: "Module affilié",
      module_type: "Type de module",
      protocol: "Protocole",
      operator: "Opérateur",
      type: "Type",
      module_address: "Adresse module",
      clock_synchro: "Synchro d'horloge",
      gutermann: "Gutermann",
      concentrator: "Concentrateur",
      repeaters: "Répéteurs",
      repeater: "Répéteur",
      channel: "Canal",
      refresh_purposes_status: "Raffraichissement du statut des intentions",
      create_campaign_purposes: "Création des intentions de la campagne",
      campaign__template_name: "Template utilisé",
      codec_path: "Codec utilisé",
      campaign__max_days: "Délai d'expiration",
    },
  },
};

i18n
  .use(detector) // detect user language
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr",

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
