import dataService from "../../Common/Services/dataService";
import { SearchField } from "@applications-terrains/birdz-react-library";

export const commonFilters = [
  "created_at_after",
  "created_at_before",
  "device_types__in",
  "operator__protocol__in",
  "operator__in",
  "customers__in",
  "contracts__in",
  "cities__in",
  "name",
  "frame_template__in",
  "created_by__in",
  "state__in",
];

export const purposeFilters = [
  "nack_cause__in",
  "campaign__in",
  "device__address",
  "txreject__reject_id__in",
  "id"
];

export function getAvailableSearchFilters(
  t: any = () => ""
): Record<string, SearchField> {
  return {
    nackCause: {
      name: "nack_cause__in",
      type: "select-multiple",
      options: {
        values: dataService
          .getData("nackCauses")
          ?.nack_causes?.map((nackCause: any) => {
            return { value: nackCause, label: nackCause };
          }),
        searchIsMulti: true,
      },
      label: "Statut nack",
    },

    purposeId: {
      type: 'text',
      name: "id",
      label: "Intention (id)",
    },

    purposeDevice: {
      name: "device__address",
      type: "text",
      label: "Device cible",
    },

    purposeState: {
      name: "aggregated_state__in",
      type: "select-multiple",
      options: {
        values: [
          // { value: null, label: "" },
          { value: "scheduled", label: t("status.scheduled") },
          { value: "processing", label: t("status.processing") },
          { value: "pending", label: t("status.pending") },
          { value: "retrying", label: t("status.retrying") },
          { value: "processed", label: t("status.processed") },
          { value: "queued", label: t("status.queued") },
          { value: "canceled", label: t("status.canceled") },
          { value: "expired", label: t("status.expired") },
          { value: "error", label: t("status.error") },
          { value: "command_succeed", label: t("status.command_succeed") },
          { value: "command_failed", label: t("status.command_failed") },
          { value: "sent_without_ack", label: t("status.sent_without_ack") },
          { value: "stopped", label: t("status.stopped") },
        ],
        searchIsMulti: true,
      },
      label: "Statut intention",
    },

    campaignState: {
      name: "state__in",
      type: "select-multiple",
      options: {
        values: [
          // { value: null, label: "" },
          { value: "created", label: t("status.created") },
          { value: "scheduled", label: t("status.scheduled") },
          { value: "creating_purposes", label: t("status.creating_purposes") },
          { value: "sending_purposes", label: t("status.sending_purposes") },
          { value: "polling_purposes", label: t("status.polling_purposes") },
          { value: "error", label: t("status.error") },
          { value: "done", label: t("status.done") },
          { value: "deleted", label: t("status.deleted") },
          { value: "stopped", label: t("status.stopped") },
        ],
      },
      label: "Statut campagne",
    },

    txreject__reject_id__in: {
      name: "txreject__reject_id__in",
      label: "Statut TX Reject",
      type: "select-multiple",
      options: {
        values: dataService.getData("txrejectCauses").txreject_causes
          ? Object.keys(
              dataService.getData("txrejectCauses").txreject_causes
            )?.map((txrejectCause: any) => {
              return {
                value: txrejectCause,
                label:
                  dataService.getData("txrejectCauses").txreject_causes[
                    txrejectCause
                  ],
              };
            })
          : [],
      },
    },
  };
}

export const searchFields: SearchField[] = [
  // {
  //   name: "created_at_after",
  //   label: "Entre le ",
  //   type: "datepicker",
  // },
  // {
  //   name: "created_at_before",
  //   label: "et le",
  //   type: "datepicker",
  // },

  {
    name: "device_types__in",
    label: "Type de matériel",
    options: {
      source: "/api/lists/device-types/?ordering=name&",
    },
  },
  {
    name: "frame_template__in",
    label: "Template",
    options: {
      source: "/api/lists/frame-templates/?ordering=name&",
    },
  },
  {
    name: "name",
    type: "text",
    label: "Campagne (nom)",
  },
  {
    name: "campaign",
    type: "text",
    label: "Campagne (id)",
  },
  {
    name: "created_by__in",
    options: {
      source: "/api/lists/users/?ordering=name&",
    },
    label: "Créé par",
  },
  {
    name: "customers__in",
    label: "Client",
    options: {
      source: "/api/customers/?ordering=code&",
      label: "code",
      identifier: "code",
    },
    seeMore: true,
  },
  {
    name: "contracts__in",
    label: "Contrat",
    options: {
      source: "/api/contracts/?ordering=code&",
      label: "code",
      identifier: "code",
    },
    seeMore: true,
  },
  {
    name: "cities__in",
    label: "Commune",
    options: {
      source: "/api/cities/",
      formatResponse: (result: any) => {
        return {
          value: result.city_id,
          label: `${result["city_id"]} ${result["name"] ?? ""}`,
        };
      },
    },
    seeMore: true,
  },
  {
    name: "operator__protocol__in",
    label: "Protocole",
    options: {
      source: "/api/lists/protocols/?ordering=name&",
    },
    seeMore: true,
  },
  {
    name: "operator__in",
    label: "Opérateur",
    options: {
      source: "/api/lists/operators/?ordering=name&",
    },
    seeMore: true,
  },
];
