import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { CampaignFrameTemplate } from '../CampaignFrameTemplate';
import { CampaignSaveButtons } from '../CampaignSaveButtons';
import { campaignService } from '../CampaignService';
import { CampaignOperator } from '../CampaignOperator';
import CampaignMultiProtocols from '../CampaignMultiProtocols/CampaignMultiProtocols';
import dataService from '../../Common/Services/dataService';
import { useNavigate } from 'react-router-dom';
import { CampaignDeviceConfiguration } from './CampaignDeviceConfiguration';
import { CampaignType, Device } from '../../types';
import { ScheduleOptions } from '../../Common/ScheduleOptions/ScheduleOptions';
import { TextField } from '@mui/material';
import {
    AppPaper,
    BirdzDialog,
    BirdzNotif,
    BirdzTitle,
    useDialog,
    useNotif
} from '@applications-terrains/birdz-react-library';
import _ from 'lodash';
import { useCampaignContext } from '../../../contexts/CampaignContext';

const CONFIGURATION: CampaignType = 'configuration';
const DEFAULT_CAMPAIGN = {
    type: CONFIGURATION
};

export const ConfigurationCampaign = () => {
    const [campaign, setCampaign] = useState<any>(DEFAULT_CAMPAIGN);
    const [hasCombinedAddress, setHasCombinedAddress] = useState<boolean>(false);
    const { confirmDialog, closeDialog, dialogOptions } = useDialog();
    const { notif, notifOptions } = useNotif();

    const navigate = useNavigate();
    const { duplicate } = useCampaignContext();

    useEffect(() => {
        setCampaign(
            duplicate
                ? { ...duplicate.campaign, name: duplicate.campaign.name + '_COPIE' }
                : DEFAULT_CAMPAIGN
        );
    }, [duplicate]);

    const resetForm = () => {
        confirmDialog({
            title: 'Souhaitez-vous vraiment annuler vos saisies?',
            onValidate: () => {
                setCampaign(DEFAULT_CAMPAIGN);
                closeDialog();
            },
            onCancel: () => {
                closeDialog();
            }
        });
    };

    const operator = campaign?.operator ? dataService.getOperator(campaign?.operator) : null;

    return (
        <AppPaper>
            <BirdzTitle>
                <Trans>Création d'une intention de configuration</Trans>
            </BirdzTitle>
            <AppPaper sx={{ mb: 1 }}>
                <h3>
                    <Trans>Étape 1: équipement cible</Trans>
                </h3>

                <CampaignOperator
                    onChange={(operator: any) => {
                        setCampaign({
                            ...campaign,
                            ...{
                                operator: operator?.value,
                                scheduled_date: duplicate ? campaign.scheduled_date : undefined,
                                sending_schedule: duplicate ? campaign.sending_schedule : undefined
                            }
                        });
                    }}
                    operator={operator ? { value: operator.id, label: operator.name } : undefined}
                />

                {campaign.operator !== undefined && (
                    <CampaignDeviceConfiguration
                        onChange={(configurationDevices: Device[], devicesString) => {
                            let newCampaign = {
                                ...campaign,
                                ...{
                                    devices: configurationDevices,
                                    multi_protocols_values: undefined,
                                    frame_template: duplicate ? campaign.frame_template : undefined,
                                    scheduled_date: duplicate ? campaign.scheduled_date : undefined,
                                    sending_schedule: duplicate
                                        ? campaign.sending_schedule
                                        : undefined
                                }
                            };
                            setHasCombinedAddress(devicesString?.includes('combined_address'));
                            // if (operator) {
                            //   newCampaign.operator = operator
                            // }
                            setCampaign(newCampaign);
                        }}
                        operatorId={campaign.operator}
                    />
                )}
            </AppPaper>
            {campaign.devices?.length > 0 && (
                <>
                    <AppPaper sx={{ mb: 1, p: 2 }}>
                        <h3>
                            <Trans>Étape 2: action sur cible</Trans>
                        </h3>

                        {/* Multi protocol */}
                        {campaignService.getCampaignProtocols(campaign?.devices, campaign?.operator)
                            .length > 1 ? (
                            <CampaignMultiProtocols
                                devices={campaign.devices}
                                type="configuration"
                                onChange={(value: any) => {
                                    setCampaign({
                                        ...campaign,
                                        ...{
                                            multi_protocols_values: value
                                        }
                                    });
                                }}
                                hasCombinedAddress={hasCombinedAddress}
                            />
                        ) : (
                            <>
                                <CampaignFrameTemplate
                                    onChange={(selectedFrameTemplate) => {
                                        if (selectedFrameTemplate) {
                                            setCampaign({
                                                ...campaign,
                                                ...{ frame_template: selectedFrameTemplate.value }
                                            });
                                            campaignService.checkDeviceTypes(
                                                campaign.devices,
                                                selectedFrameTemplate.value
                                            );
                                        }
                                    }}
                                    type={'configuration'}
                                    protocolId={
                                        campaignService.getCampaignProtocols(
                                            campaign?.devices,
                                            campaign?.operator
                                        )[0]
                                    }
                                    device_types={_.uniq(
                                        campaign.devices.map((item: any) => {
                                            return item.device_type;
                                        })
                                    )}
                                    hasCombinedAddress={hasCombinedAddress}
                                />
                                <ScheduleOptions
                                    onChange={(campaignType: any) => {
                                        setCampaign((campaign: any) => {
                                            return {
                                                ...campaign,
                                                ...{
                                                    scheduled_date: campaignType.scheduledDate,
                                                    sending_schedule: campaignType.type
                                                }
                                            };
                                        });
                                    }}
                                    value={{
                                        type: campaign.sending_schedule,
                                        scheduledDate: campaign.scheduled_date
                                    }}
                                    operatorId={campaign?.operator}
                                    protocolId={
                                        campaignService.getCampaignProtocols(
                                            campaign?.devices,
                                            campaign?.operator
                                        )[0]
                                    }
                                />
                            </>
                        )}
                    </AppPaper>

                    {/* {(campaign?.frame_template || campaign?.frame_templates) && ( */}

                    <AppPaper>
                        <h3>
                            <Trans>Étape 3: nommer la campagne</Trans>
                        </h3>
                        <TextField
                            type={'text'}
                            size="small"
                            fullWidth
                            onChange={(e: any) => {
                                setCampaign({
                                    ...campaign,
                                    ...{ name: e.target.value }
                                });
                            }}
                            value={campaign.name || ''}
                        />
                    </AppPaper>
                    {/* )} */}

                    {campaign.devices?.length > 0 && (
                        <CampaignSaveButtons
                            onReset={() => resetForm()}
                            onSave={() => {
                                const {
                                    id,
                                    created_at,
                                    created_by_token,
                                    error_message,
                                    state,
                                    updated_at,
                                    ...campaignToDuplicate
                                } = campaign;
                                const campaignToSave = duplicate ? campaignToDuplicate : campaign;
                                campaignService.save(campaignToSave).then(
                                    (success) => {
                                        notif(campaignService.successSavingNotification());
                                        setTimeout(() => {
                                            navigate('/sent-list?tab=campaigns');
                                        }, 3000);
                                    },
                                    (error) => notif(campaignService.errorSavingNotification(error))
                                );
                            }}
                            saveBtnDisabled={!campaign.name}
                        />
                    )}
                </>
            )}
            <BirdzNotif options={notifOptions} />
            <BirdzDialog options={dialogOptions} />
        </AppPaper>
    );
};

export default ConfigurationCampaign;
