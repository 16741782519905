import { Box, Button } from '@mui/material';
import { BeatLoader } from 'react-spinners';
import { useEffect } from 'react';
import { useCampaignContext } from '../../../contexts/CampaignContext';

type CampaignDevicesValidationButtonProps = {
    disabled: boolean;
    onClick(): void;
    isLoading: boolean;
};
const CampaignDevicesValidationButton = ({
    disabled,
    onClick,
    isLoading
}: CampaignDevicesValidationButtonProps) => {
    
    const { clickToCheckDevices, setClickToCheckDevices } = useCampaignContext();

    useEffect(() => {
        const button = document.getElementById('verify');
        if (button && clickToCheckDevices === 'click') {
            button.click()
            setClickToCheckDevices('clicked')
        }
    }, [clickToCheckDevices, setClickToCheckDevices]);

    return (
        <Box sx={{ textAlign: 'center' }}>
            <Button
                disabled={disabled}
                onClick={onClick}
                variant="contained"
                sx={{ m: 2 }}
                id={'verify'}>
                {isLoading ? (
                    <>
                        <BeatLoader
                            size={6}
                            style={{ marginRight: '10px' }}
                            color={'#253053'}
                            loading={true}
                        />
                        Vérification en cours
                    </>
                ) : (
                    <>Vérifier la saisie</>
                )}
            </Button>
        </Box>
    );
};

export default CampaignDevicesValidationButton;
