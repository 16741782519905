import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import { campaignService } from '../CampaignService';
import { CampaignSaveButtons } from '../CampaignSaveButtons';
import { CampaignDeviceDevEUI } from '../CampaignDeviceDevEUI';
import { CampaignCodec, getCodecsPath, getCodecsPathByDeviceType } from '../CampaignCodec';
import { useNavigate } from 'react-router-dom';
import { CampaignOperator } from '../CampaignOperator';
import { CampaignPort } from '../CampaignPort';
import { CampaignHeaderConfig } from '../CampaignHeaderConfig';
import { CampaignMaxDays } from '../CampaignMaxDays';
import { CampaignMultiAntenna } from '../CampaignMultiAntenna';
import dataService from '../../Common/Services/dataService';
import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import {
    AppPaper,
    BirdzDialog,
    BirdzNotif,
    BirdzTitle,
    useDialog,
    useNotif
} from '@applications-terrains/birdz-react-library';
import { ScheduleOptions } from '../../Common/ScheduleOptions/ScheduleOptions';
import { CampaignType, Device, DeviceType } from '../../types';
import { useCampaignContext } from '../../../contexts/CampaignContext';

const RAW: CampaignType = 'raw';
const DEFAULT_CAMPAIGN = {
    payload_clear: {},
    type: RAW,
    encryption: false
};

export const RawCampaign = (props: any) => {
    const [campaign, setCampaign] = useState<any>(DEFAULT_CAMPAIGN);
    const navigate = useNavigate();
    const { confirmDialog, closeDialog, dialogOptions } = useDialog();
    const { notif, notifOptions } = useNotif();

    const { duplicate } = useCampaignContext();

    useEffect(() => {
        setCampaign(
            duplicate
                ? { ...duplicate.campaign, name: duplicate.campaign.name + '_COPIE', encryption: duplicate.purpose.encrypted_frame !== null }
                : DEFAULT_CAMPAIGN
        );
    }, [duplicate]);

    const resetForm = () => {
        confirmDialog({
            title: 'Souhaitez-vous vraiment annuler vos saisies?',
            onValidate: () => {
                setCampaign(DEFAULT_CAMPAIGN);
                closeDialog();
            },
            onCancel: () => {
                closeDialog();
            }
        });
    };

    const operator = campaign?.operator ? dataService.getOperator(campaign?.operator) : null;

    const displayEncryption = () => {
        const deviceTypes = (campaign?.devices || []).map((device: Device) =>
            dataService.getDeviceType(device.device_type, true)
        );
        return deviceTypes.filter((el: DeviceType | undefined) => el).map((el: DeviceType) => el.encryption_keys).includes(false);
    };

    return (
        <AppPaper>
            <BirdzTitle>
                deviceType
                <Trans>Création d'une intention raw</Trans>
            </BirdzTitle>

            <AppPaper>
                <h3>
                    <Trans>
                        Étape 1: équipement cible (DevEUI ou ID HR) et chaîne de transmission
                    </Trans>
                </h3>
                <CampaignOperator
                    onChange={(operator: any) => {
                        const newCampaign = { ...campaign };
                        if (!campaignService.operatorUseFTP(operator?.value)) {
                            newCampaign.header_config = null;
                        }
                        setCampaign({
                            ...newCampaign,
                            ...{
                                operator: operator?.value,
                                scheduled_date: duplicate ? campaign.scheduled_date : undefined,
                                sending_schedule: duplicate ? campaign.sending_schedule : undefined
                            }
                        });
                    }}
                    operator={operator ? { value: operator.id, label: operator.name } : undefined}
                    allowNullOperator={false}
                />

                {campaign?.operator !== undefined && (
                    <CampaignDeviceDevEUI
                        onChange={(
                            devices
                            //  operator
                        ) => {
                            const newCampaign = { ...campaign, ...{ devices } };
                            // if (operator) {
                            //   newCampaign.operator = operator
                            // }
                            setCampaign(newCampaign);
                        }}
                        operatorId={campaign.operator}
                        campaignType={'raw'}
                    />
                )}
            </AppPaper>

            {campaign.devices && campaign.devices.length > 0 && (
                <>
                    <AppPaper sx={{ mt: 1 }}>
                        <h3>
                            <Trans>Étape 2: choisir le type de trame de l'encodeur</Trans>
                        </h3>

                        <CampaignMultiAntenna
                            onChange={(isMultiAntenna) => {
                                setCampaign({
                                    ...campaign,
                                    ...{
                                        is_multi_antenna: isMultiAntenna
                                    }
                                });
                            }}
                        />

                        <Grid container alignItems={'center'}>
                            <Grid item xs={4} alignSelf={'flex-start'}>
                                Payload brut
                            </Grid>
                            <Grid item xs={8} sx={{ mb: 1 }}>
                                <TextField
                                    type={'textarea'}
                                    multiline
                                    rows={6}
                                    fullWidth
                                    onChange={(e: any) => {
                                        setCampaign({
                                            ...campaign,
                                            ...{
                                                frame: e.target.value
                                            }
                                        });
                                    }}
                                    value={campaign.frame}
                                />
                            </Grid>
                            {displayEncryption() && (
                                <>
                                    <Grid item xs={4}>
                                        Encrypter
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={(e: any) => {
                                                        setCampaign((previousCampaign: any) => {
                                                            return {
                                                                ...previousCampaign,
                                                                encryption: e.target.checked
                                                            };
                                                        });
                                                    }}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    checked={campaign.encryption === true}
                                                    value={props.value}
                                                />
                                            }
                                            label=""
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 18 },
                                                '& .MuiFormControlLabel-label': { fontSize: '15px' }
                                            }}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>

                        <CampaignCodec
                            onChange={(codec) => {
                                setCampaign({
                                    ...campaign,
                                    ...{
                                        codecs_path_ack: getCodecsPathByDeviceType(
                                            campaign.devices,
                                            codec
                                        )
                                    }
                                });
                            }}
                            codec={getCodecsPath(campaign.codecs_path_ack)}
                            label="Codec accusé de réception"
                            type="ack"
                        />
                    </AppPaper>

                    {campaign?.operator &&
                        campaignService.operatorUseHRProtocol(campaign?.operator) && (
                            <CampaignHeaderConfig
                                label="Étape 3: header"
                                onChange={(headerConfig: any) => {
                                    setCampaign({
                                        ...campaign,
                                        ...{
                                            header_config: headerConfig
                                        }
                                    });
                                }}
                                value={campaign?.header_config}
                            />
                        )}

                    {campaign.frame && campaign.operator && (
                        <>
                            <AppPaper sx={{ mt: 1 }}>
                                <h3>
                                    <Trans>
                                        Étape{' '}
                                        {campaign.operator &&
                                        campaignService.operatorUseFTP(campaign.operator)
                                            ? '4'
                                            : '3'}
                                        : action sur cible
                                    </Trans>
                                </h3>

                                <CampaignPort
                                    onChange={(port: number) => {
                                        setCampaign({
                                            ...campaign,
                                            ...{ port }
                                        });
                                    }}
                                    value={campaign.port}
                                />

                                <ScheduleOptions
                                    onChange={(campaignType: any) => {
                                        setCampaign((campaign: any) => {
                                            return {
                                                ...campaign,
                                                ...{
                                                    scheduled_date: campaignType.scheduledDate,
                                                    sending_schedule: campaignType.type
                                                }
                                            };
                                        });
                                    }}
                                    value={{
                                        type: campaign.sending_schedule,
                                        scheduledDate: campaign.scheduled_date
                                    }}
                                    operatorId={campaign.operator}
                                />

                                <CampaignMaxDays
                                    onChange={(max_days: number) => {
                                        setCampaign({
                                            ...campaign,
                                            ...{ max_days }
                                        });
                                    }}
                                    value={campaign.max_days}
                                />
                            </AppPaper>

                            <AppPaper sx={{ mt: 1 }}>
                                <h3>
                                    <Trans>Étape 5: nommer la campagne</Trans>
                                </h3>

                                <TextField
                                    type={'text'}
                                    size="small"
                                    fullWidth
                                    onChange={(e: any) => {
                                        setCampaign({
                                            ...campaign,
                                            ...{ name: e.target.value }
                                        });
                                    }}
                                    value={campaign.name || ''}
                                />
                            </AppPaper>
                        </>
                    )}

                    <CampaignSaveButtons
                        onReset={() => resetForm()}
                        onSave={() => {
                            const {id, created_at, created_by_token, error_message, state, updated_at, ...campaignToDuplicate} = campaign;
                            const campaignToSave = duplicate ? campaignToDuplicate : campaign;
                            campaignService.save(campaignToSave).then(
                                () => {
                                    notif(campaignService.successSavingNotification());
                                    setTimeout(() => {
                                        navigate('/sent-list?tab=campaigns');
                                    }, 3000);
                                },
                                (error) => notif(campaignService.errorSavingNotification(error))
                            );
                        }}
                        saveBtnDisabled={!campaign.name}
                    />
                </>
            )}
            <BirdzNotif options={notifOptions} />
            <BirdzDialog options={dialogOptions} />
        </AppPaper>
    );
};

export default RawCampaign;
