import { FC } from 'react';
import { CampaignContextProvider } from './CampaignContext';
import { ResetFormContextProvider } from './ResetFormContext';

export type Children = {
    children?: React.ReactNode;
};

const AppContextProvider: FC<Children> = ({ children }) => {
    return (
        <CampaignContextProvider>
            <ResetFormContextProvider>{children}</ResetFormContextProvider>
        </CampaignContextProvider>
    );
};

export default AppContextProvider;
