import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import { ContentCopy } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useCampaignContext } from '../../../../contexts/CampaignContext';
import moment from 'moment';

const DuplicateButtonWithSpinner = ({
    url,
    purpose
}: {
    url: string;
    purpose: { id: number | string };
}) => {
    const navigate = useNavigate();
    const { notif, notifOptions } = useNotif();
    const duplicateContext = useCampaignContext();

    const [isLoading, setIsLoading] = useState(false);

    let endpoints = ['/api/purposes/' + purpose.id + '/', url];

    return (
        <>
            <IconButton
                onClick={() => {
                    setIsLoading(true);
                    //https://blog.logrocket.com/using-axios-all-make-concurrent-requests/
                    axios
                        .all(endpoints.map((endpoint) => axios.get(endpoint)))
                        .then(
                            axios.spread(({ data: purpose }, { data: campaign }) => {
                                setIsLoading(false);
                                const path = `/campaigns/${campaign.type}`;
                                setIsLoading(false);
                                duplicateContext.setDuplicate({
                                    purpose,
                                    campaign: {...campaign, scheduled_date: campaign.sending_schedule === 'scheduled' ? moment(new Date()).add(6, 'hours').format() : campaign.scheduled_date}
                                });
                                navigate(path);
                            })
                        )
                        .catch((err) => {
                            setIsLoading(false);
                            duplicateContext.setDuplicate(null);
                            notif({
                                content:
                                    'Une erreur est survenue lors de la récupération des données nécessaires à la duplication',
                                type: 'error'
                            });
                        });
                }}>
                <ContentCopy fontSize="small" />
            </IconButton>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
                open={isLoading}
                onClick={() => setIsLoading(false)}>
                <CircularProgress color="inherit" />
                <Box sx={{ mt: 1 }}>récupération des données nécessaires à la duplication</Box>
            </Backdrop>
            <BirdzNotif options={notifOptions} />
        </>
    );
};

export default DuplicateButtonWithSpinner;
