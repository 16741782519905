import React from "react";
import { Box, Button } from "@mui/material";
import { Replay, Save } from "@mui/icons-material";
import { useCampaignContext } from "../../contexts/CampaignContext";

type CampaignSaveButtonsProps = {
  onSave(): void;
  onReset(): void;
  saveBtnDisabled?: boolean;
};

export const CampaignSaveButtons = ({
  onSave,
  onReset,
  saveBtnDisabled,
}: CampaignSaveButtonsProps) => {
  const { hasFrameTemplate } = useCampaignContext();
  return (
    <Box sx={{ textAlign: 'center', mt: 1 }}>
      <Button
        value="Annuler"
        onClick={() => onReset()}
        variant="outlined"
        sx={{ mr: 1 }}
      >
        <Replay fontSize="small" sx={{ mr: 1 }} /> Annuler
      </Button>
      <Button
        disabled={saveBtnDisabled || hasFrameTemplate === false}
        onClick={() => onSave()}
        variant="contained"
      >
        <Save fontSize="small" sx={{ mr: 1 }} /> Enregistrer
      </Button>
    </Box>
  );
};
